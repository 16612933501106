"use client";

import useCurrency from "hooks/useCurrency";
import { Viewer } from "hooks/useViewer";
import i18n from "i18n/client";
import { useLayoutEffect, useMemo } from "react";
import { I18nextProvider } from "react-i18next";

import { assertLanguageByShortCode } from "@holibob-packages/language";
import { LocalizationProvider } from "@holibob-packages/ui-core/contexts";

export type LanguageProviderProps = {
    /**
     * 2 or 5 character locale string, eg "en" or "en-GB"
     */
    locale: string;
    children: React.ReactNode;
    viewer: Viewer;
};

export function LanguageProvider({ children, locale, viewer }: LanguageProviderProps) {
    const language = assertLanguageByShortCode(locale);
    const [currency] = useCurrency();

    const i18nInstance = useMemo(() => {
        return i18n.cloneInstance({ lng: locale });
    }, [locale]);

    useLayoutEffect(() => {
        const dir = i18n.dir(locale);
        if (typeof document !== "undefined" && document.body.dir !== dir) {
            document.body.dir = dir;
        }
    }, [locale]);

    return (
        <I18nextProvider i18n={i18nInstance}>
            <LocalizationProvider viewer={viewer} language={language} currency={currency}>
                {children}
            </LocalizationProvider>
        </I18nextProvider>
    );
}
