import Script, { ScriptProps } from "next/script";
import { useId, useMemo } from "react";

type CustomScriptsProps = { id?: string; html?: string };
export function CustomScripts({ id, html }: CustomScriptsProps) {
    const uniqueId = useId();
    const _id = id ?? uniqueId;

    const scriptParamsList = useMemo<ScriptProps[]>(() => {
        if (typeof window === "undefined" || !html) return [];

        const template = document.createElement("template");
        template.innerHTML = html;

        return Array.from(template.content.querySelectorAll("script")).map<ScriptProps>(({ innerHTML, src }, idx) => {
            return {
                id: `${_id}:${idx}`,
                ...(src && { src }),
                strategy: "afterInteractive",
                dangerouslySetInnerHTML: { __html: innerHTML },
            };
        });
    }, [html, _id]);

    return scriptParamsList.map((scriptParams) => <Script key={scriptParams.id} {...scriptParams} />);
}
