import { ThemeOptions } from "@mui/material/styles";

import { ThemeMode } from "../BrandedThemeProvider";

export const createComponents = (mode: ThemeMode): ThemeOptions["components"] => {
    return mode === "HUB" ? HUB_DEFAULT : BOOKING_DEFAULT;
};

const BOOKING_DEFAULT: ThemeOptions["components"] = {
    MuiButton: {
        styleOverrides: {
            root: {
                textTransform: "none",
                "&:not([data-leave-me-alone])": {
                    fontSize: "0.875em",
                    "@media (min-width:600px)": {
                        fontSize: "1.09375em",
                    },
                },
            },
        },
    },
    MuiCard: {
        styleOverrides: {
            root: {
                borderRadius: 10,
                boxShadow: "none",
                borderStyle: "solid",
                borderColor: "#CCC",
                borderWidth: 1,
            },
        },
    },
    MuiCardHeader: {
        styleOverrides: {
            root: {
                paddingLeft: 30,
                paddingRight: 30,
            },
            title: {
                fontWeight: "normal",
            },
        },
    },
    MuiDialog: {
        styleOverrides: {
            paper: {
                backgroundColor: "white",
            },
        },
    },
    MuiCardContent: {
        styleOverrides: {
            root: {
                paddingLeft: 30,
                paddingRight: 30,
            },
        },
    },
    MuiMenu: {
        styleOverrides: {
            paper: {
                backgroundColor: "#FFF",
            },
        },
    },
    MuiMenuItem: {
        styleOverrides: {
            root: {
                "& >a": {
                    textDecoration: "none",
                    color: "#666",
                },
                backgroundColor: "#FFF",
            },
        },
    },
    MuiPaper: {
        styleOverrides: {
            root: {
                "& .MuiCalendarPicker-root": {
                    "& .MuiPickersDay-root.Mui-selected": {
                        color: "white",
                    },
                },
                "& .MuiClockPicker-root": {
                    "& .Mui-selected": {
                        color: "white",
                    },
                },
            },
        },
    },
};

const HUB_DEFAULT: ThemeOptions["components"] = {
    MuiAppBar: {
        styleOverrides: {
            root: {
                boxShadow: "none",
            },
        },
    },
    MuiButton: {
        styleOverrides: {
            root: {
                textTransform: "none",
                fontSize: 16,
            },
            outlinedPrimary: {
                color: "rgba(0, 0, 0, 0.87)",
                borderColor: "rgba(0, 0, 0, 0.23)",
                "&:hover": {
                    borderColor: "rgba(0, 0, 0, 0.23)",
                    backgroundColor: "rgba(0, 0, 0, 0.04)",
                },
                "&.MuiButtonGroup-grouped:not(:last-of-type):hover": {
                    borderRightColor: "transparent",
                },
            },
        },
    },
    MuiCard: {
        styleOverrides: {
            root: {
                boxShadow: "none",
                borderStyle: "solid",
                borderColor: "#CCC",
                borderWidth: 1,
            },
        },
    },
    MuiCardHeader: {
        styleOverrides: {
            root: {
                paddingLeft: 20,
                paddingRight: 20,
                fontWeight: "bold",
                backgroundColor: "#D6D1CA",
                padding: 8,
            },
        },
    },
    MuiCardContent: {
        styleOverrides: {
            root: {
                paddingLeft: 20,
                paddingRight: 20,
                paddingTop: 5,
                paddingBottom: 5,
            },
        },
    },
    MuiMenu: {
        styleOverrides: {
            paper: {
                backgroundColor: "white",
            },
        },
    },
    MuiMenuItem: {
        styleOverrides: {
            root: {
                "& >a": {
                    textDecoration: "none",
                    color: "#666",
                },
                "&.Mui-selected": {
                    background: "none",
                    color: "#FF9001",
                    "&:hover": {
                        background: "none",
                    },
                },
                "&:hover": {
                    background: "none",
                    color: "#FF9001",
                    opacity: 0.8,
                },
            },
        },
    },
    MuiTableCell: {
        styleOverrides: {
            root: {
                fontSize: "0.75rem",
            },
        },
    },
    MuiTypography: {
        styleOverrides: {
            gutterBottom: {
                marginBottom: "1.0em",
            },
        },
    },
    MuiPopover: {
        styleOverrides: {
            paper: {
                backgroundColor: "white",
            },
        },
    },
    MuiAutocomplete: {
        styleOverrides: {
            paper: {
                backgroundColor: "white",
            },
        },
    },
    MuiDialog: {
        styleOverrides: {
            paper: {
                backgroundColor: "white",
            },
        },
    },
    MuiListItem: {
        styleOverrides: {
            root: {
                "&.Mui-selected": {
                    background: "none",
                    color: "#FF9001",
                    "&:hover": {
                        background: "none",
                    },
                },
                "&:hover": {
                    color: "#FF9001",
                    opacity: 0.8,
                },
            },
            button: {
                "&:hover": {
                    backgroundColor: "none",
                },
            },
        },
    },
    MuiInputLabel: {
        styleOverrides: {
            root: {
                color: "rgba(0,0,0,0.4)",
            },
        },
    },
    MuiInputBase: {
        styleOverrides: {
            root: {
                "&.Mui-disabled": {
                    background: "rgba(0,0,0,0.1)",
                },
            },
            input: {
                "&.Mui-disabled": {
                    cursor: "not-allowed",
                },
            },
        },
    },
    MuiPaper: {
        styleOverrides: {
            root: {
                "& .MuiCalendarPicker-root": {
                    "& .MuiPickersDay-root.Mui-selected": {
                        color: "white",
                    },
                },
                "& .MuiClockPicker-root": {
                    "& .Mui-selected": {
                        color: "white",
                    },
                },
            },
        },
    },
};
