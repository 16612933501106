import { ThemeOptions } from "@mui/material/styles";

import { FontFragment } from "../../../gql-request";
import { CustomTypographyOptions } from "../../../style/CustomTypography";
import { CUSTOM_FONT_NAME, ThemeMode } from "../BrandedThemeProvider";

export const createTypography = (
    mode: ThemeMode,
    primaryFont?: string | null,
    specialFont?: FontFragment | null
): CustomTypographyOptions => {
    if (mode === "HUB") return HUB_DEFAULT;
    const result: CustomTypographyOptions = {
        ...BOOKING_DEFAULT,
        ...(primaryFont && { fontFamily: `${primaryFont}, sans-serif` }),
    };

    if (specialFont) {
        result.specialFontFamily = getSpecialFontFamily(specialFont);
    }
    return result;
};

function getSpecialFontFamily(specialFont: FontFragment) {
    if (specialFont.__typename === "FontExternal") {
        return [specialFont.name, "sans-serif"].join(",");
    }

    return [CUSTOM_FONT_NAME, "sans-serif"].join(",");
}

const BOOKING_DEFAULT: ThemeOptions["typography"] = {
    fontFamily: [
        "Montserrat",
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
    ].join(","),
    h1: {
        fontSize: "1.85em",
        "@media (min-width:600px)": {
            fontSize: "2.5em",
        },
    },
    h2: {
        fontSize: "1.5em",
        "@media (min-width:600px)": {
            fontSize: "2.18em",
        },
    },
    h3: {
        fontSize: "1.25em",
        "@media (min-width:600px)": {
            fontSize: "2em",
        },
    },
    h4: {
        fontSize: "1.2em",
        "@media (min-width:600px)": {
            fontSize: "1.5em",
        },
    },
    body1: {
        fontSize: "1.0em",
        "@media (min-width:600px)": {
            fontSize: "1.2em",
        },
    },
    body2: {
        fontSize: "0.87em",
        "@media (min-width:600px)": {
            fontSize: "1.12em",
        },
    },
    subtitle1: {
        color: "#6F7271",
    },
    subtitle2: {
        color: "#6F7271",
    },
    caption: {
        color: "#6F7271",
    },
};

const HUB_DEFAULT: CustomTypographyOptions = {
    allVariants: {
        color: "#666666",
        WebkitFontSmoothing: "antialiased",
    },
    fontFamily: [
        "Roboto",
        "-apple-system",
        "system-ui",
        "Roboto",
        "Montserrat",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
    ].join(","),
    h1: {
        fontSize: "1.4em",
        fontWeight: "bold",
        paddingBottom: 12,
    },
    h2: {
        fontSize: "1.3em",
    },
    h3: {
        fontSize: "1.2em",
    },
    h4: {
        fontSize: "1.1em",
    },
    h5: {
        fontWeight: "bold",
        fontSize: "1em",
    },
    subtitle1: {
        fontSize: "1.4em",
        paddingTop: 15,
    },
    subtitle2: {
        color: "#6F7271",
        fontSize: "1.2em",
        paddingTop: 15,
    },
    body1: {
        color: "#000000cc",
        fontSize: "1em",
        lineHeight: "1.5em",
    },
    body2: {
        color: "#000000cc",
        fontSize: "0.8em",
    },
    caption: {
        color: "#000000cc",
    },
};
