import { Language, LanguageStruct } from "./Language";
import { DEFAULT_LANGUAGE, LANGUAGES, LanguageType } from "./data";

export function findLanguageByCode(code: string) {
    return LANGUAGES.find((language) => language.matchesCode(code));
}

export function assertLanguageByCode(code: string) {
    return assertsLanguage(findLanguageByCode(code), `by code "${code}"`);
}

export function findLanguageByShortCode(shortCode: string) {
    return LANGUAGES.find((language) => language.matchesCode(shortCode));
}

export function assertLanguageByShortCode(shortCode: string) {
    return assertsLanguage(findLanguageByShortCode(shortCode), `by short code "${shortCode}"`);
}

const LANGUAGES_BY_GRAPHQL_CODE = new Map<string, LanguageStruct>(
    LANGUAGES.map((x) => {
        return [x.graphQLCode.toLowerCase(), x];
    })
);

export function findLanguageByGraphQLCode(graphQLCode: string) {
    return LANGUAGES_BY_GRAPHQL_CODE.get(graphQLCode.toLowerCase());
}

export function assertLanguageByGraphQLCode(graphQLCode: string) {
    return assertsLanguage(findLanguageByGraphQLCode(graphQLCode), `by graphQL code "${graphQLCode}"`);
}

export function findLanguageById(id: string) {
    return LANGUAGES.find((x) => x.id === id);
}

export function assertLanguageById(id: string) {
    return assertsLanguage(findLanguageById(id), `by id "${id}"`);
}

function assertsLanguage(language: LanguageStruct | undefined, name: string): LanguageStruct {
    if (!language) {
        throw new Error(`Could not find language: ${name}`);
    }
    return language;
}

export function isDefaultLanguage(language: Language) {
    return language.id === DEFAULT_LANGUAGE.id;
}

export function isLanguage(value: unknown): value is LanguageType {
    return typeof value === "object" && value !== null && value instanceof LanguageStruct;
}
