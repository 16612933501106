import { useTheme } from "@mui/material/styles";

export const KlaroStyleOverride = () => {
    const theme = useTheme();

    return (
        <style jsx global>
            {`
                /* Fonts */
                .klaro {
                    --font-family: ${theme.typography.body1.fontFamily};
                    --font-size: ${theme.typography.body1.fontSize};
                }

                /* Colors */
                .klaro {
                    /* background color */
                    --dark1: #fff;

                    /* font color */
                    --light1: #222;

                    /* link color */
                    --green1: ${theme.palette.primary.main};
                    --blue1: ${theme.palette.primary.main};
                    --border-radius: 10px;
                }

                .klaro .cookie-modal .cm-bg {
                    background: rgba(0, 0, 0, 0.8) !important;
                }
                .klaro .cm-btn.cm-btn-lern-more {
                    color: gray !important;
                    text-decoration: underline;
                    background: transparent !important;
                    border: transparent !important;
                }
                .klaro .cm-btn.cm-btn-lern-more:hover {
                    text-decoration: none !important;
                }

                /* fix button for arabic language */
                .klaro .cm-switch {
                    direction: ltr;
                }

                /* fix modal notice on mobile */
                .klaro .cookie-modal-notice {
                    box-sizing: border-box;
                }

                /* hide close button on consent-modal */
                .klaro .cm-modal button.hide {
                    display: none;
                }
            `}
        </style>
    );
};
