import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { DistributionChannelContentDocument, DistributionChannelContentQuery } from "gql";

import { Language } from "@holibob-packages/language";

export type CreateApolloClientOptions = {
    graphqlApiUrl: string;
    origin: string;
    language: Language;
    currency: string;
    authToken: string | null;
    cache: {
        distributionChannelContent: DistributionChannelContentQuery;
    };
};

export function createApolloClient(options: CreateApolloClientOptions) {
    const headersLink = createHeadersLink(options);
    const httpLink = new HttpLink({ uri: options.graphqlApiUrl });
    const link = headersLink.concat(httpLink);

    const cache = new InMemoryCache({
        typePolicies: {
            ConsumerTripContent: {
                keyFields: [],
            },
        },
    });
    cache.writeQuery({
        query: DistributionChannelContentDocument,
        data: options.cache.distributionChannelContent,
    });

    return new ApolloClient({
        ssrMode: typeof window === "undefined",
        link,
        cache,
    });
}

function createHeadersLink(options: CreateApolloClientOptions) {
    return setContext((_, _context) => {
        const res = { headers: _context.headers ?? {} };

        res.headers["x-holibob-application"] = "BOOKING_WEB";
        res.headers["ssrorigin"] = options.origin;
        res.headers["x-holibob-language"] = options.language.code;
        res.headers["x-holibob-currency"] = options.currency;

        if (options.authToken) res.headers["Authorization"] = options.authToken;

        return res;
    });
}
