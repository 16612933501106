import Script from "next/script";

import { useDefaultConfig, useGA4MeasurementId, useGoogleTagManagerId } from "./googleAnalyticsHooks";

type GoogleTagManagerProps = {
    googleTagManagerId?: string | null;
};
function GoogleTagManager({ googleTagManagerId }: GoogleTagManagerProps) {
    if (!googleTagManagerId) return null;

    return (
        <Script
            strategy="afterInteractive"
            id={`gtm-${googleTagManagerId}`}
            dangerouslySetInnerHTML={{
                __html: `
                        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                        })(window,document,'script','dataLayer','${googleTagManagerId}');
                        `,
            }}
        />
    );
}

type GoogleAnalytics4Props = {
    measurementId?: string | null;
};
function GoogleAnalytics4({ measurementId }: GoogleAnalytics4Props) {
    const defaultConfig = useDefaultConfig();
    if (!measurementId) return null;
    return (
        <>
            <Script
                id="gtag-initialize-part-2"
                strategy="afterInteractive"
                dangerouslySetInnerHTML={{
                    __html: `
                            typeof gtag === "function" && gtag('config', '${measurementId}', ${JSON.stringify(
                                defaultConfig
                            )});
                        `,
                }}
            />
            <Script
                id="ga4"
                src={`https://www.googletagmanager.com/gtag/js?id=${measurementId}`}
                strategy="afterInteractive"
            />
        </>
    );
}

function GoogleScripts() {
    const measurementId = useGA4MeasurementId();
    const googleTagManagerId = useGoogleTagManagerId();

    return (
        <>
            <GoogleTagManager googleTagManagerId={googleTagManagerId} />
            <GoogleAnalytics4 measurementId={measurementId} />
        </>
    );
}

export default GoogleScripts;
