import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { ReactNode } from "react";

import { NotificationContext, useNotificationContext, useNotificationContextValue } from "../hooks/useNotification";

export const NotificationProvider = ({ children }: { children: ReactNode }) => {
    const value = useNotificationContextValue();

    return (
        <NotificationContext.Provider value={value}>
            <NotificationSnackbar />
            {children}
        </NotificationContext.Provider>
    );
};

function NotificationSnackbar() {
    const [notification, setNotification] = useNotificationContext();

    const handleClose = () => {
        setNotification(null);
    };

    const open = !!notification;

    const message = notification?.message ?? null;
    const severity = notification?.severity ?? "success";
    const anchorOrigin = notification?.anchorOrigin ?? { vertical: "bottom", horizontal: "center" };

    return (
        <Snackbar open={open} autoHideDuration={5000} onClose={handleClose} anchorOrigin={anchorOrigin}>
            <MuiAlert onClose={handleClose} severity={severity}>
                {message}
            </MuiAlert>
        </Snackbar>
    );
}
