import { setCookie, destroyCookie } from "nookies";

import { OVERRIDE_SSR_ORIGIN_COOKIE_NAME } from "@holibob-packages/next-utils/OVERRIDE_SSR_ORIGIN_COOKIE_NAME";

import { CONSUMER_TRIP_COOKIE_NAME } from "./consumerTrip/consumerTripCookie";

if (typeof window !== "undefined") {
    const HolibobWindowInterface = {
        setOrigin: (origin: string) => {
            setCookie(null, OVERRIDE_SSR_ORIGIN_COOKIE_NAME, origin, { path: "/" });
            // consumer trip must be reset when the origin is changed
            destroyCookie(null, CONSUMER_TRIP_COOKIE_NAME, { path: "/" });
            window.location.reload();
        },
        resetOrigin: () => {
            destroyCookie(null, OVERRIDE_SSR_ORIGIN_COOKIE_NAME, { path: "/" });
            // consumer trip must be reset when the origin is changed
            destroyCookie(null, CONSUMER_TRIP_COOKIE_NAME, { path: "/" });
            window.location.reload();
        },
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).holibob = HolibobWindowInterface;
}
