"use client";

import { useServerInsertedHTML } from "next/navigation";
import { useState } from "react";
import { StyleRegistry, createStyleRegistry } from "styled-jsx";

export function StyledJsxRegistry({ children }: { children: React.ReactNode }) {
    // Only create stylesheet once with lazy initial state
    // x-ref: https://reactjs.org/docs/hooks-reference.html#lazy-initial-state
    const [jsxStyleRegistry] = useState(() => createStyleRegistry());

    useServerInsertedHTML(() => {
        const styles = jsxStyleRegistry.styles();
        jsxStyleRegistry.flush();
        return <>{styles}</>;
    });

    return <StyleRegistry registry={jsxStyleRegistry}>{children}</StyleRegistry>;
}
