"use client";

import { ApolloProvider as ReactApolloProivder } from "@apollo/client";
import { useMemo } from "react";
import { createApolloClient, CreateApolloClientOptions } from "utils/createApolloClient";

type ApolloProviderProps = {
    children: React.ReactNode;
} & CreateApolloClientOptions;

const ApolloProvider = ({ children, ...options }: ApolloProviderProps) => {
    const client = useMemo(() => {
        return createApolloClient(options);
        // Because options is an object coming from RSC props,
        // the best way to check for changes is to stringify and compare if actual values changed.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(options)]);

    return <ReactApolloProivder client={client}>{children}</ReactApolloProivder>;
};

export default ApolloProvider;
