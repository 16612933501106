import { z } from "zod";

export const ConsumerTripCookieSchema = z.object({
    consumerTrip: z.object({
        id: z.string(),
        reference: z.string().nullish(),
    }),
    consumer: z.object({
        id: z.string(),
        reference: z.string().nullish(),
    }),
});
