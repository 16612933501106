"use client";

import type { NextWebVitalsMetric } from "next/app";
import { useReportWebVitals } from "next/web-vitals";
import { useEffect, useRef } from "react";

import { pushMetrics } from "@holibob-packages/ui-core/metrics";
import { useRouterPathname } from "@holibob-packages/ui-core/navigation";

/**
 * @internal For some reason, the same metric is reported multiple times by web vitals.
 * Keep track of what we've already reported so we don't report the same metric multiple times.
 */
const seenVitalsByValue = new Set<number>();

export type WebMetricsProps = {
    application: "booking-web" | "hub-web";
};
export function WebMetrics({ application }: WebMetricsProps) {
    const pathname = useRouterPathname();
    const pathnameRef = useRef(pathname);

    useEffect(() => {
        pathnameRef.current = pathname;
    }, [pathname]);

    useReportWebVitals((metric: NextWebVitalsMetric) => {
        switch (metric.name) {
            case "TTFB":
            case "FCP":
            case "LCP": {
                if (seenVitalsByValue.has(metric.value)) {
                    // don't report the same metric multiple time
                    return;
                }
                seenVitalsByValue.add(metric.value);
                pushMetrics([
                    {
                        MetricName: metric.name,
                        Dimensions: [
                            {
                                Name: "application",
                                Value: application,
                            },
                            {
                                Name: "path",
                                Value: pathnameRef.current,
                            },
                        ],
                        Unit: "Milliseconds",
                        Value: metric.value,
                        Timestamp: new Date(),
                    },
                    {
                        MetricName: metric.name,
                        Dimensions: [
                            {
                                Name: "application",
                                Value: application,
                            },
                            {
                                Name: "host",
                                Value: window.location.host,
                            },
                            {
                                Name: "path",
                                Value: pathnameRef.current,
                            },
                        ],
                        Unit: "Milliseconds",
                        Value: metric.value,
                        Timestamp: new Date(),
                    },
                ]);
                break;
            }
            default: {
                console.log("Unhandled metric", metric);
            }
        }
    });

    return null;
}
