import { FontFaceLoaderStyle, FontFaceLoaderWeight } from "../hooks";

export type CSSFontFaceLoaderProps = {
    url: string;
    fontWeight: FontFaceLoaderWeight;
    fontStyle: FontFaceLoaderStyle;
    fontName: string;
};

export function CSSFontFaceLoader({ url, fontWeight, fontStyle, fontName }: CSSFontFaceLoaderProps) {
    const content = `
    @font-face {
        font-family: ${fontName};
        font-style: ${fontStyle};
        font-weight: ${fontWeight};
        font-display: swap;
        src: url(${url});
    }
    `;
    return <style>{content}</style>;
}
