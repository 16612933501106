import { DateRange, enchant, Range } from "@pallad/range";

import { DateRangeSchema } from "@holibob-packages/date";
import { dayjs } from "@holibob-packages/dayjs";

/**
 * Date range that rounds start and end dates to the beginning and end of the day respectively.
 */
export const DayDateRangeSchema = DateRangeSchema.transform((range) => {
    return enchant(range).map({
        start: ({ start }) => {
            return Range.create(dayjs(start).startOf("day").toDate());
        },
        end: ({ end }) => {
            return Range.create(undefined, dayjs(end).endOf("day").toDate());
        },
        full: ({ start, end }) => {
            return Range.create(dayjs(start).startOf("day").toDate(), dayjs(end).endOf("day").toDate());
        },
    });
});

export function createDayDateRange(
    start: undefined | null | string | Date,
    end: undefined | null | string | Date
): DateRange | undefined {
    if (!start && !end) {
        return undefined;
    }
    return DayDateRangeSchema.parse({ start, end });
}
