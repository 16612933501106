import { SnackbarProps } from "@mui/material";
import { AlertProps } from "@mui/material/Alert";
import { useState, createContext } from "react";

import { useContextHelper } from "./useContextHelper";

export type Notification = {
    message: string | React.ReactNode | undefined;
    severity?: AlertProps["severity"];
    anchorOrigin?: SnackbarProps["anchorOrigin"];
};

type NotificationContextValue = ReturnType<typeof useNotificationContextValue>;
export const NotificationContext = createContext<NotificationContextValue | null>(null);

export const useNotificationContextValue = () => useState<Notification | null>(null);

export const useNotificationContext = () => useContextHelper(NotificationContext);

export const useNotification = (initialMessage?: Notification["message"]) => {
    const [, setNotification] = useNotificationContext();

    return (
        messageProp: Notification["message"],
        severity?: Notification["severity"],
        anchorOrigin?: Notification["anchorOrigin"]
    ) => {
        const message = messageProp ?? initialMessage;
        const notification: Notification = {
            message,
            severity,
            anchorOrigin,
        };

        setNotification(notification);
    };
};
