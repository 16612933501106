import { Range } from "@pallad/range";
import { z } from "zod";

export const DateRangeSchema = z
    .object({
        start: z.coerce.date().nullish(),
        end: z.coerce.date().nullish(),
    })
    .transform(({ start, end }, ctx) => {
        try {
            return Range.fromArray([start, end] as Date[]);
        } catch (error) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: error.message,
            });
            return z.NEVER;
        }
    });
