import { _metrics } from "./_metrics";

export function setupMetricsBeacon(graphqlApiUrl: string) {
    if (typeof window !== "undefined" && typeof navigator.sendBeacon === "function") {
        /**
         * We use visibility change to detect when the user has switched away from the tab or has closed the tab.
         * Then we use sendBeacon to asynchronously send the metrics regardless of tab state.
         */
        document.addEventListener("visibilitychange", function logData() {
            if (document.visibilityState === "hidden" && _metrics.current.length > 0) {
                const origin = new URL(graphqlApiUrl).origin;
                navigator.sendBeacon(
                    `${origin}/mtriks`,
                    new Blob([JSON.stringify(_metrics.current)], { type: "text/plain" })
                );
                _metrics.current.length = 0;
            }
        });
    }
}
