import * as Sentry from "@sentry/nextjs";
import { useConsumerTripInfo } from "hooks/useConsumerTripInfo";
import { useEntityConfigurationValueByKey } from "hooks/useEntityConfiguration";
import Script from "next/script";
import { createContext, useEffect } from "react";
import { useState } from "react";

type BrazeSdk = typeof window.braze;

const isDevelopment = process.env.NODE_ENV === "development";

export type BrazeSdkContext = {
    brazeSdk: typeof window.braze;
};

export const BrazeSdkContext = createContext<BrazeSdkContext | null>(null);

type BrazeSdkProviderProps = {
    children: React.ReactNode;
};

export function BrazeSdkProvider({ children }: BrazeSdkProviderProps) {
    const brazeSdkConfiguration = useBrazeSdkConfiguration();
    const [brazeSdk, setBrazeSdk] = useState<BrazeSdk>(undefined);
    const { consumerId } = useConsumerTripInfo();

    const onError = (error: unknown) => {
        setBrazeSdk(undefined);
        Sentry.captureException(error, { tags: { brazeSdk: "brazeSdkException" } });
    };

    useEffect(() => {
        if (!brazeSdkConfiguration) {
            return;
        }

        if (!brazeSdk) {
            executeBrazeSdkVendorCode();
            const initialiseConfig = { ...brazeSdkConfiguration, consumerId };
            setBrazeSdk(initialiseBrazeSdk(initialiseConfig, onError));
        }

        return () => {
            brazeSdk?.destroy();
        };
    }, [brazeSdkConfiguration, brazeSdk, consumerId]);

    return (
        <BrazeSdkContext.Provider value={{ brazeSdk }}>
            {brazeSdkConfiguration && (
                <Script
                    strategy="afterInteractive"
                    id="braze-sdk"
                    src="https://js.appboycdn.com/web-sdk/5.3/braze.min.js"
                />
            )}
            {children}
        </BrazeSdkContext.Provider>
    );
}

type InitialiseBrazeSdkConfig = BrazeSdkConfiguration & {
    consumerId: string;
};

const initialiseBrazeSdk = (config: InitialiseBrazeSdkConfig, onError: (error: unknown) => void) => {
    try {
        if (!window.braze) return undefined;

        const braze = window.braze;
        const { brazeSdkApiKey, brazeSdkApiEndpoint, consumerId } = config;

        const isBrazeInitialised = braze.initialize(brazeSdkApiKey, {
            baseUrl: brazeSdkApiEndpoint,
            minimumIntervalBetweenTriggerActionsInSeconds: isDevelopment ? 1 : undefined,
            enableLogging: isDevelopment,
            allowUserSuppliedJavascript: true,
        });

        // should always be executed before braze.openSession()
        braze.changeUser(consumerId);

        braze.automaticallyShowInAppMessages();

        braze.subscribeToSdkAuthenticationFailures(onError);
        braze.openSession();

        return isBrazeInitialised ? braze : undefined;
    } catch (e) {
        onError(e);
    }

    return undefined;
};

type BrazeSdkConfiguration = {
    brazeSdkApiKey: string;
    brazeSdkApiEndpoint: string;
};

function useBrazeSdkConfiguration(): BrazeSdkConfiguration | null {
    const brazeSdkApiKey = useEntityConfigurationValueByKey("BRAZE_SDK.API_KEY") as string | undefined | null;
    const brazeSdkApiEndpoint = useEntityConfigurationValueByKey("BRAZE_SDK.API_ENDPOINT") as string | undefined | null;
    const hasConfiguration = brazeSdkApiKey && brazeSdkApiEndpoint;

    return hasConfiguration ? { brazeSdkApiKey, brazeSdkApiEndpoint } : null;
}

function executeBrazeSdkVendorCode() {
    /**
     * This is a code extract from vendor. Other approach was to use `dangerouslySetInnerHTML` but it way much unsafe than doing this.
     */
    const api = {} as NonNullable<BrazeSdk>;
    window.braze = api;
    window.brazeQueue = [];
    const methods = [
        "BrazeSdkMetadata",
        "DeviceProperties",
        "Card",
        "Card.prototype.dismissCard",
        "Card.prototype.removeAllSubscriptions",
        "Card.prototype.removeSubscription",
        "Card.prototype.subscribeToClickedEvent",
        "Card.prototype.subscribeToDismissedEvent",
        "Card.fromContentCardsJson",
        "ImageOnly",
        "CaptionedImage",
        "ClassicCard",
        "ControlCard",
        "ContentCards",
        "ContentCards.prototype.getUnviewedCardCount",
        "Feed",
        "Feed.prototype.getUnreadCardCount",
        "ControlMessage",
        "InAppMessage",
        "InAppMessage.SlideFrom",
        "InAppMessage.ClickAction",
        "InAppMessage.DismissType",
        "InAppMessage.OpenTarget",
        "InAppMessage.ImageStyle",
        "InAppMessage.Orientation",
        "InAppMessage.TextAlignment",
        "InAppMessage.CropType",
        "InAppMessage.prototype.closeMessage",
        "InAppMessage.prototype.removeAllSubscriptions",
        "InAppMessage.prototype.removeSubscription",
        "InAppMessage.prototype.subscribeToClickedEvent",
        "InAppMessage.prototype.subscribeToDismissedEvent",
        "InAppMessage.fromJson",
        "FullScreenMessage",
        "ModalMessage",
        "HtmlMessage",
        "SlideUpMessage",
        "User",
        "User.Genders",
        "User.NotificationSubscriptionTypes",
        "User.prototype.addAlias",
        "User.prototype.addToCustomAttributeArray",
        "User.prototype.addToSubscriptionGroup",
        "User.prototype.getUserId",
        "User.prototype.getUserId",
        "User.prototype.incrementCustomUserAttribute",
        "User.prototype.removeFromCustomAttributeArray",
        "User.prototype.removeFromSubscriptionGroup",
        "User.prototype.setCountry",
        "User.prototype.setCustomLocationAttribute",
        "User.prototype.setCustomUserAttribute",
        "User.prototype.setDateOfBirth",
        "User.prototype.setEmail",
        "User.prototype.setEmailNotificationSubscriptionType",
        "User.prototype.setFirstName",
        "User.prototype.setGender",
        "User.prototype.setHomeCity",
        "User.prototype.setLanguage",
        "User.prototype.setLastKnownLocation",
        "User.prototype.setLastName",
        "User.prototype.setPhoneNumber",
        "User.prototype.setPushNotificationSubscriptionType",
        "InAppMessageButton",
        "InAppMessageButton.prototype.removeAllSubscriptions",
        "InAppMessageButton.prototype.removeSubscription",
        "InAppMessageButton.prototype.subscribeToClickedEvent",
        "FeatureFlag",
        "FeatureFlag.prototype.getStringProperty",
        "FeatureFlag.prototype.getNumberProperty",
        "FeatureFlag.prototype.getBooleanProperty",
        "FeatureFlag.prototype.getImageProperty",
        "FeatureFlag.prototype.getJsonProperty",
        "FeatureFlag.prototype.getTimestampProperty",
        "automaticallyShowInAppMessages",
        "destroyFeed",
        "hideContentCards",
        "showContentCards",
        "showFeed",
        "showInAppMessage",
        "deferInAppMessage",
        "toggleContentCards",
        "toggleFeed",
        "changeUser",
        "destroy",
        "getDeviceId",
        "getDeviceId",
        "initialize",
        "isPushBlocked",
        "isPushPermissionGranted",
        "isPushSupported",
        "logCardClick",
        "logCardDismissal",
        "logCardImpressions",
        "logContentCardImpressions",
        "logContentCardClick",
        "logCustomEvent",
        "logFeedDisplayed",
        "logInAppMessageButtonClick",
        "logInAppMessageClick",
        "logInAppMessageHtmlClick",
        "logInAppMessageImpression",
        "logPurchase",
        "openSession",
        "requestPushPermission",
        "removeAllSubscriptions",
        "removeSubscription",
        "requestContentCardsRefresh",
        "requestFeedRefresh",
        "refreshFeatureFlags",
        "requestImmediateDataFlush",
        "enableSDK",
        "isDisabled",
        "setLogger",
        "setSdkAuthenticationSignature",
        "addSdkMetadata",
        "disableSDK",
        "subscribeToContentCardsUpdates",
        "subscribeToFeedUpdates",
        "subscribeToInAppMessage",
        "subscribeToSdkAuthenticationFailures",
        "toggleLogging",
        "unregisterPush",
        "wipeData",
        "handleBrazeAction",
        "subscribeToFeatureFlagsUpdates",
        "getAllFeatureFlags",
        "logFeatureFlagImpression",
    ] as const;
    for (const method of methods) {
        const methodProps = method.split(".");
        // eslint-disable-next-line @typescript-eslint/no-implied-eval
        api[methodProps[methodProps.length - 1] as keyof typeof api] = new Function(
            "return function " + method.replace(/\./g, "_") + "(){window.brazeQueue.push(arguments); return true}"
        )();
    }

    api.getDeferredInAppMessage = function () {
        return new api.InAppMessage();
    };
    api.getCachedContentCards = function () {
        return new api.ContentCards([], null);
    };
    api.getCachedFeed = function () {
        return new api.Feed([], null);
    };
    api.getUser = function () {
        return new api.User();
    };
    api.getFeatureFlag = function () {
        return new api.FeatureFlag();
    };
}
