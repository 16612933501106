import { useEntityConfigurationByTypes } from "hooks/useEntityConfiguration";

import { CustomScripts } from "@holibob-packages/ui-core/components";

export function ConfigurationCustomScripts() {
    const customScriptConfigurations = useEntityConfigurationByTypes("CUSTOM_SCRIPT");

    return customScriptConfigurations.map((customScriptConfiguration) => (
        <CustomScripts
            key={customScriptConfiguration.id}
            id={customScriptConfiguration.id}
            html={customScriptConfiguration.value}
        />
    ));
}
