"use client";

// eslint-disable-next-line @typescript-eslint/no-restricted-imports -- It's fine here
import { useParams, usePathname } from "next/navigation";

/**
 * Returns the routing pathname for the currnet route.
 *
 * This mimicks the `useRouter().pathname` API from the NextJS pages router.
 *
 * It gives you the router pathname, eg `/[locale]/product/[id]` for the route `/en/product/123`.
 */
export function useRouterPathname() {
    let pathname = usePathname() ?? "";
    const params = useParams() ?? {};

    for (const [segment, value] of Object.entries(params)) {
        pathname = pathname.replace(value as string, `[${segment}]`);
    }
    if (pathname.startsWith("/[locale]")) {
        pathname = pathname.replace("/[locale]", "");
    }
    if (pathname === "") {
        pathname = "/";
    }

    return pathname;
}
