import { ViewerContext } from "hooks/useViewer";

import { ViewerInfo } from "../types/models/ViewerInfo";

type ViewerProviderProps = {
    children: React.ReactNode;
    viewerInfo: ViewerInfo;
};

export function ViewerProvider({ children, viewerInfo }: ViewerProviderProps) {
    return <ViewerContext.Provider value={viewerInfo}>{children}</ViewerContext.Provider>;
}
