import authenticator from "./authenticator";
import availability from "./availability";
import availabilityForm from "./availabilityForm";
import blog from "./blog";
import booking from "./booking";
import bookingNavigation from "./bookingNavigation";
import component from "./component";
import footer from "./footer";
import home from "./home";
import navbar from "./navbar";
import pagination from "./pagination";
import product from "./product";
import search from "./search";
import seo from "./seo";
import usps from "./usps";
import validation from "./validation";

const namespaces = {
    authenticator,
    availability,
    availabilityForm,
    booking,
    bookingNavigation,
    component,
    footer,
    home,
    navbar,
    pagination,
    product,
    search,
    usps,
    validation,
    blog,
    seo,
};

export default namespaces;
