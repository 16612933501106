import { DateRange, enchant } from "@pallad/range";
import { fromNullable } from "@sweet-monads/maybe";
import { Maybe } from "@sweet-monads/maybe";
import { ConsumerTripFragment } from "gql";
import { ReadonlyRequestCookies } from "next/dist/server/web/spec-extension/adapters/request-cookies";
import { NextRequest, NextResponse } from "next/server";
import { destroyCookie } from "nookies";

import { dayjs } from "@holibob-packages/dayjs";

import { createDayDateRange } from "../model/DateRange";
import { ConsumerTripCookieSchema } from "./model/ConsumerTripCookieSchema";
import { ConsumerTripData } from "./model/types";

export const CONSUMER_TRIP_COOKIE_NAME = "consumerTrip";

export type ConsumerTripCookieData = {
    consumerTrip: { id: string; reference?: string | null };
    consumer: { id: string; reference?: string | null };
};

export function getConsumerTripDataFromCookie(
    cookies: ReadonlyRequestCookies | NextRequest["cookies"] | NextResponse["cookies"]
): ConsumerTripCookieData | undefined {
    try {
        const consumerTripCookie = cookies.get(CONSUMER_TRIP_COOKIE_NAME)?.value;
        const consumerTripCookieParsed = consumerTripCookie ? JSON.parse(consumerTripCookie) : undefined;
        const consumerTripCookieData = ConsumerTripCookieSchema.parse(consumerTripCookieParsed);
        return consumerTripCookieData;
    } catch (error) {
        return undefined;
    }
}

export type SetConsumerTripCookieConsumerTripData =
    | Pick<ConsumerTripFragment, "arrivalDate" | "departureDate">
    | undefined;

export function setConsumerTripCookie(
    cookies: NextResponse["cookies"] | NextRequest["cookies"],
    value: ConsumerTripCookieData,
    consumerTrip: SetConsumerTripCookieConsumerTripData
) {
    const tripDateRange = fromNullable(createDayDateRange(consumerTrip?.arrivalDate, consumerTrip?.departureDate));
    const expirationDate = getConsumerTripCookieExpirationDate(tripDateRange);
    cookies.set({
        name: CONSUMER_TRIP_COOKIE_NAME,
        value: JSON.stringify(value),
        path: "/",
        expires: expirationDate,
        sameSite: "lax",
    });
}

export function deleteConsumerTripCookie(cookies: ReadonlyRequestCookies | NextRequest["cookies"]) {
    cookies.delete(CONSUMER_TRIP_COOKIE_NAME);
}

export function deleteClientConsumerTripCookie() {
    destroyCookie(null, CONSUMER_TRIP_COOKIE_NAME, { path: "/" });
}

type ConsumerTripDataForCookieInput = Pick<ConsumerTripData, "id" | "partnerExternalReference" | "consumerId"> & {
    consumer: { partnerExternalReference: string | null } | null;
};

export function getConsumerTripDataForCookie(consumerTripData: ConsumerTripDataForCookieInput) {
    const cookieData = {
        consumerTrip: {
            id: consumerTripData.id,
            reference: consumerTripData.partnerExternalReference,
        },
        consumer: {
            id: consumerTripData.consumerId,
            reference: consumerTripData.consumer?.partnerExternalReference,
        },
    };

    return cookieData;
}

export function generateConsumerTripCookieDataForAnonymous(): ConsumerTripCookieData {
    return {
        consumerTrip: {
            id: crypto.randomUUID(),
            reference: null,
        },
        consumer: {
            id: crypto.randomUUID(),
            reference: null,
        },
    };
}

export function getConsumerTripCookieExpirationDate(tripDateRange: Maybe<DateRange>): Date {
    return tripDateRange.fold(
        () => dayjs().add(6, "months").toDate(),
        (range) => {
            return enchant(range).map({
                start: ({ start }) => {
                    return dayjs(start).add(30, "days").toDate();
                },
                end: ({ end }) => {
                    return end;
                },
                full: ({ end }) => {
                    return end;
                },
            });
        }
    );
}
