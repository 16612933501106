import availability from "./availability";
import availabilityForm from "./availabilityForm";
import explore from "./explore";
import language from "./language";
import product from "./product";

const namespaces = {
    availability,
    availabilityForm,
    language,
    product,
    explore,
};

export default namespaces;
