import { constants } from "@holibob-packages/ui-core/style";

export function GlobalStyle() {
    return (
        <style
            dangerouslySetInnerHTML={{
                __html: `
        html,
        body,
        #__next {
            height: 100%;
            background: ${constants.DEFAULT_BACKGROUND_COLOR};
            margin: 0px;
        }

        body {
            overflow-y: scroll;
        }

        a:hover,
        a:visited,
        a:link,
        a:active,
        a:-webkit-any-link {
            text-decoration: none;
            color: inherit;
        }

        body #hubspot-messages-iframe-container {
            z-index: 100;
        }

        .lg-backdrop {
            z-index: 1300;
        }
        .lg-outer {
            z-index: 1301;
        }

        @import url("https://fonts.googleapis.com/css2?family=Montserrat");
    `,
            }}
        />
    );
}

export default GlobalStyle;
