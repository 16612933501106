import { PaletteOptions } from "@mui/material/styles";

import { BrandForThemeFragment } from "../../../apiHooks/graphql";
import { CustomPalette, SpotColourPreset } from "../../../style";

const DEFAULT_LIGHT = "#FFF";
const DEFAULT_DARK = "#000";

export type Palette = CustomPalette & PaletteOptions;

export function createPalette(brand: BrandForThemeFragment): Palette {
    const spotColours = new Map<number, SpotColourPreset>(
        brand.spotColours.map((x) => {
            return [
                x.index,
                {
                    40: x.shade40,
                    70: x.shade70,
                    100: x.shade100,
                    400: x.shade400,
                    700: x.shade700,
                },
            ] as [number, SpotColourPreset];
        })
    );

    return {
        // BRANDED
        primary: {
            main: brand.primaryColour.main,
            contrastText: brand.primaryColour.contrast,
        },
        secondary: {
            main: brand.secondaryColour.main,
            contrastText: brand.secondaryColour.contrast,
        },
        header: {
            main: brand.headerColour.main,
            contrastText: brand.headerColour.contrast,
        },
        footer: {
            main: brand.footerColour.main,
            contrastText: brand.footerColour.contrast,
        },
        buttonPrimary: {
            main: brand.primaryButtonColour.main,
            contrastText: brand.primaryButtonColour.contrast,
        },
        buttonSecondary: {
            main: brand.secondaryButtonColour.main,
            contrastText: brand.secondaryButtonColour.contrast,
        },

        spotColours,

        // HARDCODED
        priority: {
            level1: {
                main: "#caffbf",
            },
            level2: {
                main: "#fcd2af",
            },
            level3: {
                main: "#ff9770",
            },
            level4: {
                main: "#ffa6c1",
            },
            level5: {
                main: "#ff87ab",
            },
            level6: {
                main: "#ff5d8f",
            },
        },
        blue: {
            main: "#3D7CC9",
        },
        green: {
            main: "#00B189",
        },
        red: {
            main: "#E7004C",
        },
        light: {
            main: DEFAULT_LIGHT,
        },
        dark: {
            main: DEFAULT_DARK,
        },
        link: {
            main: "#00CFB3",
            visited: "#00CFB3",
            hover: "#00CFB3",
        },
        control: {
            border: "#0000003b",
            focused: "#ff8a00",
        },
        overlay: {
            light: "#00000021",
            cover: "#00000081",
        },
        border: {
            subtle: "#00000021",
        },
    };
}
