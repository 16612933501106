import type { CloudWatch } from "aws-sdk";

/**
 * @internal
 */
export const _metrics: { current: CloudWatch.Types.MetricData } = { current: [] };

/**
 * @internal
 */
export function _pushMetrics(newMetrics: CloudWatch.Types.MetricData) {
    _metrics.current.push(...newMetrics);
}
