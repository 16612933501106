import i18next from "i18next";
import * as locales from "locales";
import merge from "lodash/merge";
import { initReactI18next } from "react-i18next";

import * as uiCoreLocales from "@holibob-packages/ui-core/locales";

const resources = merge(locales, uiCoreLocales);

void i18next.use(initReactI18next).init({
    resources,
    fallbackLng: "en",
    detection: {
        caches: [],
    },
    ns: ["common"],
    defaultNS: "common",
    returnNull: false,
    interpolation: {
        escapeValue: false,
        formatSeparator: ",",
    },
    // debug: process.env.NODE_ENV !== "production",
});

export default i18next;
