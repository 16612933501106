import { useIsConsentGiven } from "components/ConsentManagement";
import { useConsumerTripInfo } from "hooks/useConsumerTripInfo";
import { useEntityConfigurationValueByKey } from "hooks/useEntityConfiguration";
import useViewer from "hooks/useViewer";
import { useEffect } from "react";

interface IHjSettings {
    hjid: string;
    hjsv: number;
}

declare global {
    interface Window {
        hj?: {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (...args: any[]): void;

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            q?: any[];
        };
        _hjSettings: IHjSettings;
    }
}

const SCRIPT_ID = "hotjar-script";

function HotjarScripts() {
    const hotJarTrackingId = useEntityConfigurationValueByKey("SETTING.HOTJAR_SITE_ID");
    const consentGiven = useIsConsentGiven("hotjar");
    const viewer = useViewer();

    const { consumerTripId, consumerId } = useConsumerTripInfo();
    const email = viewer.securityPrincipal?.user?.email;

    useEffect(() => {
        const hotjarScript = document.getElementById(SCRIPT_ID);
        if (!hotjarScript && hotJarTrackingId && consentGiven) {
            const script = document.createElement("script");
            script.async = true;
            script.id = SCRIPT_ID;
            window.hj =
                window.hj ??
                function () {
                    // eslint-disable-next-line prefer-rest-params
                    (window.hj!.q = window.hj!.q ?? []).push(arguments);
                };
            window._hjSettings = { hjid: hotJarTrackingId as string, hjsv: 6 };
            script.src = `https://static.hotjar.com/c/hotjar-${hotJarTrackingId}.js?sv=6`;
            window.hj("identify", consumerTripId, { consumerTripId, consumerId, email });
            document.head.appendChild(script);
        }

        return () => {
            document.getElementById(SCRIPT_ID)?.remove();
        };
    }, [hotJarTrackingId, consentGiven, consumerTripId, consumerId, email]);

    return null;
}

export default HotjarScripts;
