"use client";

import { LicenseInfo } from "@mui/x-license-pro";
import { ConsentManegementProvider } from "components/ConsentManagement";
import { WebMetrics } from "components/WebMetrics";
import { ConfigurationCustomScripts } from "containers/ConfigurationCustomScripts";
import GoogleAnalyticsScripts from "containers/GoogleAnalytics/GoogleAnalyticsScripts";
import HotjarScripts from "containers/HotjarScripts";
import { AppStateProvider } from "contexts/AppStateContext";
import { AuthTokenContext } from "contexts/AuthTokenContext";
import { LanguageProvider } from "contexts/LanguageProvider";
import { StyledJsxRegistry } from "contexts/StyledJsxRegistry";
import { ViewerProvider } from "contexts/ViewerProvider";
import { CurrencyProvider } from "hooks/useCurrency";
import { dir } from "i18next";
import "klaro/dist/klaro.css";
import "lightgallery/css/lg-thumbnail.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lightgallery.css";
import { Suspense, useEffect } from "react";
import GlobalStyle from "style/GlobalStyle";
import { prefixer } from "stylis";
import rtlPlugin from "stylis-plugin-rtl";
import { TssCacheProvider } from "tss-react";
import { NextAppDirEmotionCacheProvider } from "tss-react/next/appDir";
import "utils/HolibobWindowInterface";
import { ConsumerTripCookieData } from "utils/consumerTrip/consumerTripCookie";

import { BrandedThemeProvider, NotificationProvider } from "@holibob-packages/ui-core/contexts";
import { setupMetricsBeacon } from "@holibob-packages/ui-core/metrics";

import { ConsumerTripInfoProvider } from "../hooks/useConsumerTripInfo";
import { ViewerInfo } from "../types/models/ViewerInfo";
import { BrazeSdkProvider } from "./BrazeSdkProvider";
import { ConfigProvider } from "./ConfigProvider";
import PersistedQueryParamsContextProvider from "./PersistedQueryParamsContextProvider";

LicenseInfo.setLicenseKey(
    "e73d63d856b5b1dbfe868c08d3ad1c49Tz02NTYyNyxFPTE3MTQ2NTI0MzMyNjEsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

let metricsBeaconSetup = false;

export type ProvidersProps = {
    children: React.ReactNode;
    currency: string;
    viewerInfo: ViewerInfo;
    authToken: string | null;
    consumerTripCookie: ConsumerTripCookieData;
    graphqlApiUrl: string;
    host: string;
};

export function Providers({
    children,
    viewerInfo,
    authToken,
    currency,
    graphqlApiUrl,
    host,
    consumerTripCookie,
}: ProvidersProps) {
    const direction = dir(viewerInfo.viewer.language.code);
    const isRtl = direction === "rtl";

    useEffect(() => {
        if (!metricsBeaconSetup) {
            metricsBeaconSetup = true;
            setupMetricsBeacon(graphqlApiUrl);
        }
    }, [graphqlApiUrl]);

    return (
        <ConfigProvider host={host}>
            <AuthTokenContext.Provider value={authToken}>
                <AppStateProvider>
                    <WebMetrics application="booking-web" />
                    <NextAppDirEmotionCacheProvider
                        options={{ key: isRtl ? "mui" : "muirtl", stylisPlugins: isRtl ? [prefixer, rtlPlugin] : [] }}
                    >
                        <NextAppDirEmotionCacheProvider
                            options={{
                                key: isRtl ? "tssrtl" : "tssltr",
                                stylisPlugins: isRtl ? [prefixer, rtlPlugin] : [],
                            }}
                            CacheProvider={TssCacheProvider}
                        >
                            <NotificationProvider>
                                <ConsumerTripInfoProvider consumerTripCookie={consumerTripCookie}>
                                    <ViewerProvider viewerInfo={viewerInfo}>
                                        <PersistedQueryParamsContextProvider>
                                            <CurrencyProvider initialCurrency={currency}>
                                                <LanguageProvider
                                                    viewer={viewerInfo.viewer}
                                                    locale={viewerInfo.viewer.language.code}
                                                >
                                                    <GlobalStyle />
                                                    <StyledJsxRegistry>
                                                        <BrazeSdkProvider>
                                                            <BrandedThemeProvider
                                                                direction={direction}
                                                                mode="BOOKING"
                                                                brand={viewerInfo.viewer.brand}
                                                            >
                                                                <Suspense fallback={null}>
                                                                    <ConsentManegementProvider>
                                                                        <GoogleAnalyticsScripts />
                                                                        {process.env.NODE_ENV === "production" && (
                                                                            <HotjarScripts />
                                                                        )}
                                                                        {process.env.NODE_ENV === "production" && (
                                                                            <ConfigurationCustomScripts />
                                                                        )}
                                                                    </ConsentManegementProvider>
                                                                </Suspense>
                                                                {children}
                                                            </BrandedThemeProvider>
                                                        </BrazeSdkProvider>
                                                    </StyledJsxRegistry>
                                                </LanguageProvider>
                                            </CurrencyProvider>
                                        </PersistedQueryParamsContextProvider>
                                    </ViewerProvider>
                                </ConsumerTripInfoProvider>
                            </NotificationProvider>
                        </NextAppDirEmotionCacheProvider>
                    </NextAppDirEmotionCacheProvider>
                </AppStateProvider>
            </AuthTokenContext.Provider>
        </ConfigProvider>
    );
}
