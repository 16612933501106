import { Direction, createTheme } from "@mui/material/styles";

import { BrandForThemeFragment } from "../../../apiHooks/graphql";
import { ThemeMode } from "../BrandedThemeProvider";
import { createComponents } from "./createComponents";
import { createPalette } from "./createPalette";
import { createTypography } from "./createTypography";

type CreateBrandedThemeParams = {
    brand: BrandForThemeFragment;
    mode: ThemeMode;
    direction?: Direction;
};

export function createBrandedTheme({ brand, mode, direction }: CreateBrandedThemeParams) {
    return createTheme({
        palette: createPalette(brand), // mode agnostic
        typography: createTypography(mode, brand.primaryFont, brand.specialFont),
        components: createComponents(mode),
        direction,
        boxShadows: ["0 1px 3px rgba(0,0,0,0.12)", "0 0 11px rgba(33,33,33,.2)", "0 0 11px rgba(33,33,33,.5)"],
        airiness: brand.airiness * 8,
        roundness: brand.roundness,
    });
}
