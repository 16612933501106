"use client";

import { klaroConfig, KlaroConfig, KlaroServices } from "config/klaroConfig";
import type { Klaro, Manager } from "klaro/dist/klaro-no-css";
import { useSearchParams } from "next/navigation";
import { createContext, useContext, useEffect, useRef, useState } from "react";

import { Language } from "@holibob-packages/language";
import { useLanguage } from "@holibob-packages/ui-core/hooks";
import { usePathname } from "@holibob-packages/ui-core/navigation";

import { KlaroStyleOverride } from "./ConsentManagement/KlaroStyleOverride";

type ConsentManegementValue = {
    ref: React.MutableRefObject<Klaro | null>;
};
const ConsentManegementContext = createContext<ConsentManegementValue>({
    ref: { current: null },
});

declare global {
    interface Window {
        klaro?: Klaro;
        klaroConfig?: KlaroConfig;
    }
}

const PRIVACY_POLICY_ROUTE = "/holibob-privacy-policy";
type ConsentManegementProviderProps = {
    children: React.ReactNode;
};
export const ConsentManegementProvider = ({ children }: ConsentManegementProviderProps) => {
    return <ConsentManegementProviderImplementation>{children}</ConsentManegementProviderImplementation>;
};

function useAcceptedConsentsFromQueryParam(): Record<KlaroServices, boolean> | undefined {
    const searchParams = useSearchParams();
    const consentAcceptedByParams = searchParams.get("__acceptedConsent");

    if (!consentAcceptedByParams) return;

    const serviceNameList = new Set(klaroConfig.services.map((service) => service.name));
    if (consentAcceptedByParams === "none") {
        return Object.fromEntries(
            Array.from(serviceNameList).map((serviceName) => {
                return [serviceName, false];
            })
        ) as Record<KlaroServices, boolean>;
    }
    const acceptedServiceList = new Set(
        consentAcceptedByParams
            .split(",")
            .filter((consent) => serviceNameList.has(consent as KlaroServices)) as KlaroServices[]
    );

    if (acceptedServiceList.size > 0) {
        return Object.fromEntries(
            Array.from(serviceNameList).map((serviceName) => {
                return [serviceName, acceptedServiceList.has(serviceName)];
            })
        ) as Record<KlaroServices, boolean>;
    }
    return;
}

function getKlaroConfig({ language, displayModal }: { language: Language; displayModal: boolean }) {
    return {
        ...klaroConfig,
        lang: language.codeShort,
        noticeAsModal: displayModal,
        default: true,
    };
}

const ConsentManegementProviderImplementation = ({ children }: ConsentManegementProviderProps) => {
    const klaroRef = useRef<Klaro | null>(null);
    const [value, setValue] = useState<ConsentManegementValue>({
        ref: klaroRef,
    });
    const pathname = usePathname();
    const acceptedConsentFromQueryParam = useAcceptedConsentsFromQueryParam();
    const isPrivacyPolicyRoute = pathname === PRIVACY_POLICY_ROUTE;

    const lang = useLanguage();

    // we use a ref here so that we can initialize "klaro" only once
    const langRef = useRef(lang);
    useEffect(() => {
        langRef.current = lang;
    }, [lang]);

    useEffect(() => {
        let active = true;
        if (isPrivacyPolicyRoute) {
            // do not show Klaro modal on the privacy policy page
            return;
        }

        void (async () => {
            const Klaro = (await import("klaro/dist/klaro-no-css")) as Klaro;

            // eslint is wrong here
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            if (!active) {
                return;
            }

            // do not double initialize Klaro
            if (klaroRef.current) {
                return;
            }

            // we assign the Klaro module to the window, so that we can access it in JS
            window.klaro = klaroRef.current = Klaro;
            const klaroConfig = (window.klaroConfig = getKlaroConfig({
                language: langRef.current,
                displayModal: !acceptedConsentFromQueryParam,
            }));

            Klaro.setup(klaroConfig);
            setValue({ ref: klaroRef });

            if (acceptedConsentFromQueryParam) {
                const manager = Klaro.getManager();

                for (const [serviceName, accepted] of Object.entries(acceptedConsentFromQueryParam)) {
                    manager.updateConsent(serviceName, accepted);
                }
                manager.saveAndApplyConsents();
            }
        })();

        return () => {
            active = false;
        };
    }, [isPrivacyPolicyRoute, acceptedConsentFromQueryParam]);

    return (
        <ConsentManegementContext.Provider value={value}>
            <KlaroStyleOverride />
            {children}
        </ConsentManegementContext.Provider>
    );
};

export function useIsConsentGiven(service: KlaroServices): boolean {
    const { ref } = useContext(ConsentManegementContext);
    const manager = ref.current?.getManager();
    const [isEnabled, setIsEnabled] = useState(getConsentState(manager, service));

    useEffect(() => {
        setIsEnabled(getConsentState(manager, service));

        if (!manager) return;
        const watcher = {
            update: () => {
                setIsEnabled(getConsentState(manager, service));
            },
        };

        manager.watch(watcher);

        return () => {
            manager.unwatch(watcher);
        };
    }, [service, manager]);

    return isEnabled;
}

export function getConsentState(manager: Manager | undefined, service: string): boolean {
    return manager?.states[service] ?? false;
}
